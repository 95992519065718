import React from "react"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Link } from "gatsby"

export const PortfolioItemTemplate = ({ title, content, featured_media }) => {
    return (
        <div id="content">
            <SEO title={`${title} | Art Specialists`} />

            <div id="page-content">
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: content }} />

                <p>
                    <Img fluid={featured_media.localFile.childImageSharp.fluid} alt={title} />
                </p>

                <p>Back to <Link to={`/portfolio/`}>portfolio</Link>.</p>
            </div>
        </div>
    )
}

PortfolioItemTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
}

const PortfolioItem = ({ data }) => {
    const { wordpressWpPortfolio: page } = data

    return (
        <Layout sidebar="hidden">
            <PortfolioItemTemplate title={page.title} content={page.content} featured_media={page.featured_media} />
        </Layout>
    )
}

PortfolioItem.propTypes = {
    data: PropTypes.object.isRequired,
}

export default PortfolioItem

export const pageQuery = graphql`
  query PortfolioItemById($id: String!) {
    wordpressWpPortfolio(id: { eq: $id }) {
      title
      content
      featured_media {
          localFile {
              childImageSharp {
                  fluid(maxWidth: 620, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
              }
          }
      }
    }
  }
`